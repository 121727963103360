import { defineStore } from 'pinia'

export const useStoreUtils = defineStore('storeUtils', {
  state: () => {
    return {
      loading: false
    }
  },
  getters: {},
  actions: {}
})